import React, { useContext, useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import Img from 'gatsby-image'

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
// import Typography from '@material-ui/core/Typography';
import { Typography, Box, Paper, media } from '@material-ui/core';
import firebase from 'gatsby-plugin-firebase';
import ReactMarkdown from "react-markdown"

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});


const IndexPage = ( { data } ) => {
  const classes = useStyles();
  // const perf = firebase.performance();

  useEffect(() => {
    // effect
    firebase.analytics().logEvent("home")
    return () => {
      // cleanup
    }
  }, [])
  return (
  <Layout>
    <SEO title="Home" />
    <h1>ยินดีต้อนรับสู่ แหล่งรวมข่าวสารและข้อมูล สำหรับคอหวย</h1>
    <ul>
      {data.allStrapiArticle.edges.map(document => (
      //   <Card className={classes.root}>
      //   <CardActionArea>
      //     <CardMedia
      //       className={classes.media}
      //     >
      //       {document.node.image ? <Img fixed={document.node.image.childImageSharp.fixed}/> : <Image />}
      //     </CardMedia>
      //     <CardContent>
      //       <Typography gutterBottom variant="h5" component="h2">
      //         Lizard
      //       </Typography>
      //       <Typography variant="body2" color="textSecondary" component="p">
      //         Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
      //         across all continents except Antarctica
      //       </Typography>
      //     </CardContent>
      //   </CardActionArea>
      //   <CardActions>
      //     <Button size="small" color="primary">
      //       Share
      //     </Button>
      //     <Button size="small" color="primary">
      //       Learn More
      //     </Button>
      //   </CardActions>
      // </Card>
        <li key={document.node.id}>
          <h2>
            <Link to={`/${document.node.id}`}>{document.node.title}</Link>
          </h2>
          {document.node.image ? <Img fixed={document.node.image.childImageSharp.fixed}/> : <Image />}
          <ReactMarkdown source={document.node.content}/>
        </li>
      ))}
    </ul>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
    {/* <Link to="/page-2/">Go to page 2</Link> <br /> */}
    {/* <Link to="/using-typescript/">Go to "Using TypeScript"</Link> */}

    
  </Layout>
  
  );
      }

export default IndexPage

export const pageQuery = graphql`  
  query IndexQuery {
    allStrapiArticle(sort: {fields: created_at, order: DESC}) {
      edges {
        node {
          id
          title
          content
          image {
            childImageSharp {
              fixed(width: 300, height: 216) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`